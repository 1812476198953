import React, { useEffect } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { messages, currentLocale as initialLocale, setCurrentLocale } from '../I18n';
import appsignal from '../Services/ErrorReportingService';

// Define the LocaleContextType with proper typing
interface LocaleContextType {
  locale: string;
  setLocale: (locale: string) => void;
}

export const IntlProviderContext = React.createContext<LocaleContextType>({
  locale: initialLocale,
  setLocale: () => {}, // Default no-op function
});

export function IntlProvider({ children }: any) {
  const [locale, setLocale] = React.useState<string>(initialLocale);

  // Format locale for IntlProvider
  let formattedLocale = locale;
  // If the locale is Arabic, use the Moroccan variant for correct number formatting
  if (formattedLocale === 'ar') {
    formattedLocale = 'ar-MA';
  }

  const handleSetLocale = (locale: string) => {
    setCurrentLocale(locale);
    setLocale(locale);
  };

  const handleError = (error: any) => {
    console.warn('IntlProvider error:', error);
    appsignal.sendError(error);
  };

  useEffect(() => {
    // set Initial locale
    handleSetLocale(initialLocale);
  }, []);

  return (
    <IntlProviderContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      <ReactIntlProvider
        locale={formattedLocale}
        defaultLocale={initialLocale}
        messages={messages[locale]}
        onError={handleError}
      >
        {children}
      </ReactIntlProvider>
    </IntlProviderContext.Provider>
  );
}

export default IntlProviderContext;
