import React from 'react';
import { useQueryClient } from 'react-query';
import { ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useNotifications } from '@toolpad/core/useNotifications';

import Locales from '../../../Utils/Types/Locales';
import useSpace from '../../../Hooks/useSpace';
import useCurrentUser from '../../../Hooks/useCurrentUser';
import { notify } from '../../../Utils/NotificationUtils';
import useApiMutation from '../../../Hooks/useApiMutation';
import queriesConfig from '../../../Services/queriesConfig';
import useIntlProvider from '../../../Hooks/useIntlProvider';

function LanguageMenuItems({ onClick, nested = false }: any) {
  const intl = useIntl();
  const { space } = useSpace();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const notifications = useNotifications();
  const { isLoggedIn } = useCurrentUser();
  const { setLocale } = useIntlProvider();

  const spaceLocales = Locales.filter((locale) => space?.locales?.includes(locale.value));

  const { mutate } = useApiMutation({
    query: queriesConfig.updateProfile(space.id),
    onSuccess: (response) => {
      queryClient.invalidateQueries();
      notify(notifications, intl.formatMessage({ id: 'PROFILE.PROFILE_UPDATED' }));
      setLocale(response?.lang);
    },
  });

  if (!space) {
    return null;
  }

  const handleOnClick = async (locale: string) => {
    if (isLoggedIn) {
      mutate({ lang: locale });
    } else {
      navigate(`?lang=${locale}`, { replace: true });
      navigate(0); // Force refresh
    }

    onClick();
  };

  return spaceLocales.map((option: any) => (
    <ListItemButton
      key={option.value}
      onClick={() => handleOnClick(option.value)}
      role="list"
      sx={{ ...(nested && { pl: 4 }) }}
    >
      <ListItemText>{option.label}</ListItemText>
    </ListItemButton>
  ));
}

export default LanguageMenuItems;
