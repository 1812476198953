import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';
import { FormattedMessage, useIntl } from 'react-intl';

import { Images } from '../../Themes';
import Hidden from '../DesignSystem/Hidden';
import {
  getAccessibilityLinks,
  getEthicsLinks,
  getFaqsLinks,
  getPrivacyLinks,
  getTermsLinks,
} from '../../Utils/WebsiteUrls';
import useSpace from '../../Hooks/useSpace';

export const FOOTER_HEIGHT = 65;
export const MOBILE_FOOTER_HEIGHT = 100;

const StyledContainer = styled('div')(({ theme: { palette, spacing } }: any) => ({
  backgroundColor: palette.background.paper,
  padding: spacing(2, 3),
  zIndex: 20,
}));

const StyledEngageLogo = styled(ReactSVG)(() => ({
  width: '100px',
  '& svg': {
    verticalAlign: 'middle',
  },
}));

const StyledLink = styled(Link)(({ theme: { palette } }: any) => ({
  color: 'inherit',
  '&:hover': {
    opacity: 0.6,
    textDecoration: 'none',
  },
  '& g, & path': {
    fill: palette.text.primary,
  },
}));

function Footer() {
  const { locale, formatMessage } = useIntl();
  const { space } = useSpace();

  if (!space) {
    return null;
  }

  const renderFooterLink = ({ textId, link }: any) => (
    <Box sx={{ p: 0.5 }}>
      <StyledLink
        variant="body1"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        underline="hover"
        title={formatMessage({ id: 'BUTTONS.OPEN_IN_NEW_TAB' })}
      >
        <FormattedMessage id={textId} />
      </StyledLink>
    </Box>
  );

  const renderDotSeparator = () => (
    <Box sx={{ p: 0.5 }}>
      <Typography variant="body2" color="inherit">
        &#8226;
      </Typography>
    </Box>
  );

  const renderLogo = () => (
    <Box sx={{ px: 0.5 }}>
      <StyledEngageLogo src={Images.logo.engage.black} aria-hidden="true" />
      <Box sx={{ display: 'none' }}>Efalia Engage logo to Efalia Engage website</Box>
    </Box>
  );

  const termsLink = space.terms_of_use ? `/${space.slug}/pages/terms` : getTermsLinks(locale);
  const privacyLink = space.privacy_policy
    ? `/${space.slug}/pages/privacy-policy`
    : getPrivacyLinks(locale);

  return (
    <StyledContainer component="footer">
      <Hidden breakpoint="md" direction="down">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {renderLogo()}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {renderFooterLink({ textId: 'FOOTER.HELP', link: getFaqsLinks(locale) })}
            {renderDotSeparator()}
            {renderFooterLink({
              textId: 'FOOTER.ACCESSIBILITY',
              link: getAccessibilityLinks(locale),
            })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.ETHICAL_POLICY', link: getEthicsLinks(locale) })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.TERMS_OF_USE', link: termsLink })}
            {renderDotSeparator()}
            {renderFooterLink({ textId: 'FOOTER.PRIVACY_POLICY', link: privacyLink })}
          </Box>
        </Box>
      </Hidden>
      <Hidden breakpoint="md" direction="up">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          {renderLogo()}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {renderFooterLink({ textId: 'FOOTER.HELP', link: getFaqsLinks(locale) })}
          {renderFooterLink({
            textId: 'FOOTER.ACCESSIBILITY',
            link: getAccessibilityLinks(locale),
          })}
          {renderFooterLink({ textId: 'FOOTER.ETHICAL_POLICY', link: getEthicsLinks(locale) })}
          {renderFooterLink({ textId: 'FOOTER.TERMS_OF_USE', link: termsLink })}
          {renderFooterLink({ textId: 'FOOTER.PRIVACY_POLICY', link: privacyLink })}
        </Box>
      </Hidden>
    </StyledContainer>
  );
}

export default Footer;
