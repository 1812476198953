import { FormQuestion } from './FormBuilder';
import { LocaleType } from './Locales';
import { TranslatableField, Attachment } from './Utils';

export type PostType =
  | 'anomaly'
  | 'article'
  | 'demand'
  | 'event'
  | 'idea'
  | 'ideas_request'
  | 'participatory_budget'
  | 'collaborative_decision'
  | 'folder'
  | 'study';

export type PostTypeOrSurvey = PostType | 'survey';

export type ConsultationPostType = PostType &
  ('ideas_request' | 'participatory_budget' | 'collaborative_decision' | 'study');
export type AdminPostType = PostType & (ConsultationPostType | 'folder' | 'article' | 'event');

export type PostTypeUppperCase = Uppercase<PostType>;

export type PostTypePlural =
  | 'anomalies'
  | 'articles'
  | 'demands'
  | 'events'
  | 'ideas'
  | 'ideas_requests'
  | 'participatory_budgets'
  | 'collaborative_decisions'
  | 'folders'
  | 'studies';

export const Types: Record<PostTypeUppperCase, PostType> = {
  ANOMALY: 'anomaly',
  ARTICLE: 'article',
  DEMAND: 'demand',
  EVENT: 'event',
  IDEA: 'idea',
  IDEAS_REQUEST: 'ideas_request',
  PARTICIPATORY_BUDGET: 'participatory_budget',
  COLLABORATIVE_DECISION: 'collaborative_decision',
  FOLDER: 'folder',
  STUDY: 'study',
};

export const postTypePlural = (postType: PostType): PostTypePlural => {
  return {
    anomaly: 'anomalies',
    article: 'articles',
    demand: 'demands',
    event: 'events',
    idea: 'ideas',
    ideas_request: 'ideas_requests',
    participatory_budget: 'participatory_budgets',
    collaborative_decision: 'collaborative_decisions',
    folder: 'folders',
    study: 'studies',
  }[postType] as PostTypePlural;
};

export const postTypeSingular = (postType: PostTypePlural): PostType => {
  return {
    anomalies: 'anomaly',
    articles: 'article',
    demands: 'demand',
    events: 'event',
    ideas: 'idea',
    ideas_requests: 'ideas_request',
    participatory_budgets: 'participatory_budget',
    collaborative_decisions: 'collaborative_decision',
    folders: 'folder',
    studies: 'study',
  }[postType] as PostType;
};

export type Category = 'communication' | 'consultation' | 'folder' | 'messaging' | 'participation';
export type CategoryUppercase = Uppercase<Category>;

export const Categories: Record<CategoryUppercase, PostType[]> = {
  COMMUNICATION: [Types.ARTICLE, Types.EVENT],
  CONSULTATION: [
    Types.IDEAS_REQUEST,
    Types.PARTICIPATORY_BUDGET,
    Types.COLLABORATIVE_DECISION,
    Types.STUDY,
  ],
  FOLDER: [Types.FOLDER],
  MESSAGING: [Types.ANOMALY, Types.DEMAND],
  PARTICIPATION: [Types.IDEA],
};

export const AdminPostTypes: PostType[] = [
  ...Categories.COMMUNICATION,
  ...Categories.CONSULTATION,
  ...Categories.FOLDER,
] as AdminPostType[];

export const getPostTypesForCategory = (category: Category): PostType[] => {
  return Categories[category.toUpperCase() as CategoryUppercase];
};

export const getCategoryForPostType = (postType: PostType): CategoryUppercase => {
  return Object.keys(Categories).find((key) =>
    Categories[key as CategoryUppercase].includes(postType),
  ) as CategoryUppercase;
};

export const ContributionKinds = [
  'contribution',
  'proposal',
  'question',
  'report',
  'other',
] as const;
export type ContributionKind = (typeof ContributionKinds)[number];

interface DisplayOptions {
  children_default_view: string;
  children_list_pictures: boolean;
  comments_enabled: boolean;
}

interface VotingOptions {
  type: string;
  budget_rule: string | null;
  max_votes: number | null;
  min_votes: number | null;
}

interface Permissions {
  can_edit_post: boolean;
  can_assign_post: boolean;
  can_delete_post: boolean;
  can_create_ideas: boolean;
  can_create_post_permissions: boolean;
  can_create_vote: boolean;
  can_create_consultations: boolean;
  can_create_communications: boolean;
  can_create_folder: boolean;
  can_update_budget: boolean;
  can_update_status: boolean;
  can_publish_unpublish: boolean;
  can_pin_unpin: boolean;
  can_report: boolean;
  can_contact_author: boolean;
  can_access_study_results: boolean;
}

interface Action {
  name: string;
  is_primary: boolean;
}

type Step = {
  id: number;
  step_type: string;
  start_at: string;
  end_at: string | null;
  dates_status: string;
  open_to_citizen_and_contributor: boolean;
} & TranslatableField<{
  description: string;
}>;

export interface Source {
  geojson: string | null;
  problematic: string;
  budget: string | null;
  id: string;
  title: string;
  type: PostType;
  slug: string;
  published_at: string;
  current_step: Step;
}

export interface Owner {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_name: string | null;
  initials: string;
  deleted_at: string | null;
  role: string;
  picture: Attachment;
}

export interface Author {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_name: string | null;
  initials: string;
  deleted_at: string | null;
  role: string;
  picture: Attachment;
}

interface Tag {
  id: number;
  label: string;
}

type PostConfig = TranslatableField<{
  contribution_kind_label: string | null;
}> & {
  commentable: boolean;
  contribution_kind: ContributionKind | null;
};

type TranslatablePostFields = TranslatableField<{
  title: string;
  content: string;
  problematic: string;
  video_url: string | null;
  authored_by_first_name: string | null;
  authored_by_last_name: string | null;
}>;

export type Post = TranslatablePostFields & {
  ancestry_depth: number;
  attendees_count: number;
  authored_by: string;
  budget: string | null;
  citizen_id: string;
  created_at: string;
  created_on_lat?: string;
  created_on_lng?: string;
  display_options?: DisplayOptions;
  external_form_url: string | null;
  form_results_url: string | null;
  form_url: string | null;
  geojson: string | null;
  lang: LocaleType;
  latlng: string | null;
  location: string | null;
  moderatable: boolean;
  original_post_id: number | null;
  parent_id: number;
  post_records_count: number;
  slug: string;
  video_embed: string | null;
  id: string | number;
  type: PostType;
  is_pinned: boolean;
  lat: string | null;
  lng: string | null;
  status: string;
  current_status: any;
  end_at: string | null;
  start_at: string | null;
  updated_at: string;
  published_at?: string;
  ask_to_publish_at?: string;
  voting_options?: VotingOptions;
  show_votes_against: boolean;
  picture?: Attachment;
  attachments: [];
  application_file?: Attachment;
  current_step_id?: number;
  event_url?: string;
  picture_to_share_url?: string;
  permissions: Permissions;
  actions: Action[];
  form_builder?: boolean;
  has_secured_strategy_contribute: boolean;
  has_secured_strategy_comment: boolean;
  has_secured_strategy_vote: boolean;
  form_questions: FormQuestion[];
  secured_strategy_questions: FormQuestion[];
  secured_strategies_contribute: {
    logged_in: boolean;
    verified_email: boolean;
  };
  secured_strategies_comment: {
    logged_in: boolean;
    verified_email: boolean;
  };
  secured_strategies_vote: {
    logged_in: boolean;
    verified_email: boolean;
  };
  post_record_questions: FormQuestion[];
  included: {
    source: Source;
    author: Author;
    owner: Owner;
    tags: Tag[];
    steps: Step[];
  };
  post_contributions_stats: {
    participations_count: number;
    comments_count: number;
    votes_for_count: number;
    votes_for_online_count: number;
    votes_for_offline_count: number;
    votes_against_count: number;
    votes_against_online_count: number;
    votes_against_offline_count: number;
  };
  meta: {
    contributions_filters_config_address_city: boolean;
    contributions_filters_config_prof_category: boolean;
    contributions_filters_config_sex: boolean;
    voters_filters_config_address_city: boolean;
    voters_filters_config_prof_category: boolean;
    voters_filters_config_sex: boolean;
    user_votes_info: {
      total_votes: number;
      budget_spent: number;
      budget_left: number;
    };
    ai_data: {
      summary: string | null;
      moderation: boolean;
      moderation_reason: string | null;
    };
    user_form_submission_id?: number;
  };
  last_deduplication_at: string | null;
  config?: PostConfig;
};

export default Types;
