import React from 'react';
import qs from 'qs';
import { Navigate, useLocation } from 'react-router-dom';

import { isArray } from 'lodash';
import { spacePath } from '../Utils/routes';
import useSpace from '../Hooks/useSpace';
import FullScreenLoader from './FullScreenLoader';
import useCurrentUser from '../Hooks/useCurrentUser';

function GuestRoute({
  redirect = true,
  component: Component,
  defaultRedirect,
  ...props
}: {
  redirect?: boolean;
  component: React.FC<{ space?: any }>;
  defaultRedirect?: string;
}) {
  const { space } = useSpace();
  const { search } = useLocation();
  const { isLoggedIn } = useCurrentUser();
  const params = qs.parse(search?.slice(1));
  const { redirect_to: redirectTo, ...rest } = params;

  if (!space.slug) {
    return <FullScreenLoader />;
  }

  let path = defaultRedirect || redirectTo || spacePath('', rest);
  if (isArray(path)) {
    [path] = path;
  }

  if (isLoggedIn && redirect) {
    return <Navigate replace to={path} />;
  }

  return <Component space={space} {...props} />;
}

export default GuestRoute;
