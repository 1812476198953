import React from 'react';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from './FullScreenLoader';
import { loginPath } from '../Utils/routes';
import useSpace from '../Hooks/useSpace';
import useCurrentUser from '../Hooks/useCurrentUser';

function SpaceRoute({
  component: Component,
  redirect = true,
  ...props
}: {
  component: React.FC<any & { space?: any }>;
  redirect?: boolean;
}) {
  const { space } = useSpace();
  const { isLoggedIn, isLoading } = useCurrentUser();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (!space.public && !isLoggedIn && redirect) {
    return <Navigate replace to={loginPath()} />;
  }

  return <Component {...props} space={space} />;
}

export default SpaceRoute;
