import { compact } from 'lodash';
import qs from 'qs';
import {
  createFormResponse,
  updateFormResponse,
  deleteFormResponse,
  getSecuredStrategySubmission,
  createSecuredStrategySubmission,
  updateSecuredStrategySubmission,
  getAddressCities,
  getAttendances,
  getPost,
  createAttendance,
  updateAttendance,
  updateQuestion,
  getCommentsForPost,
  getDiscussionNotes,
  getFormResponse,
  getFormResponses,
  getReportReasons,
  getQuestionResults,
  getUiSections,
  getVoters,
  getImport,
  createImport,
  deleteVoter,
  deleteManyVoters,
  postComment,
  postCommentForComment,
  postDiscussionNote,
  updateMenuItems,
  updateUiSections,
  createPost,
  updatePost,
  updatePostDashboard,
  deletePost,
  deleteManyPosts,
  pinUnpinPost,
  getVotersDuplicates,
  updateVotersDuplicate,
  clonePost,
  translate,
  verifyPin,
  getCommentTranslation,
  getPostStatus,
  createPassword,
  updateMyPassword,
  resetPassword,
  updatePassword,
  updateProfile,
  getMySpaces,
  getProfile,
} from './Api';
import { Page } from '../Utils/Types/UiSection';
import { ImportScope } from '../Utils/Types/Import';

export type QueryConfig<T> = {
  queryKey: string[];
  queryFn: (...args: any[]) => Promise<T>;
};

export type MutationConfig<T> = {
  mutationKey?: any[];
  mutationFn: (...args: any[]) => Promise<T>;
};

interface QueriesConfig<T> {
  [key: string]: (...args: any[]) => QueryConfig<T> | MutationConfig<T>;
}

const queriesConfig = {
  //
  // Mutations
  //
  postDiscussionNote: (postId: number | string, { parentId, type = 'email' }: any) => {
    return {
      mutationFn: postDiscussionNote(postId, { parentId, type }),
    };
  },
  postCommentForComment: (postId: number | string, commentId: number) => {
    return {
      mutationFn: postCommentForComment(postId, commentId),
    };
  },
  postComment: (postId: number | string) => {
    return {
      mutationFn: postComment(postId),
    };
  },
  clonePost: (postId: number | string) => {
    return {
      mutationFn: clonePost(postId),
    };
  },
  createFormResponse: (postId: number | string) => {
    return {
      mutationFn: createFormResponse(postId),
    };
  },
  updateFormResponse: (postId: number | string, formResponseId: number | string) => {
    return {
      mutationFn: updateFormResponse(postId, formResponseId),
    };
  },
  createOrUpdateSecuredStrategySubmission: (
    postId: number | string,
    responseId: number | string,
  ) => {
    return {
      mutationFn: responseId
        ? updateSecuredStrategySubmission(postId, responseId)
        : createSecuredStrategySubmission(postId),
    };
  },

  deleteFormResponse: (postId: number | string, formResponseId: number | string) => {
    return {
      mutationFn: deleteFormResponse(postId, formResponseId),
    };
  },
  updateMenuItems: () => {
    return {
      mutationFn: updateMenuItems,
    };
  },
  createAttendance: (postId: number | string, attendanceStatus: string) => {
    return {
      mutationFn: createAttendance(postId, attendanceStatus),
    };
  },
  updateAttendance: (attendanceId: number | string) => {
    return {
      mutationFn: updateAttendance(attendanceId),
    };
  },
  createPost: () => {
    return {
      mutationFn: createPost,
    };
  },
  updatePost: (postId: number | string) => {
    return {
      mutationFn: updatePost(postId),
    };
  },
  updatePostDashboard: (postId: number | string) => {
    return {
      mutationFn: updatePostDashboard(postId),
    };
  },
  pinUnpin: (postId: number | string) => {
    return {
      mutationFn: pinUnpinPost(postId),
    };
  },
  deletePost: (postId: number | string) => {
    return {
      mutationFn: deletePost(postId),
    };
  },
  deleteManyPosts: (postIds: (string | number)[]) => {
    return {
      mutationFn: deleteManyPosts(postIds),
    };
  },
  updateQuestion: (postId: number | string) => {
    return {
      mutationFn: updateQuestion(postId),
    };
  },
  updateUiSections: () => {
    return {
      mutationFn: updateUiSections,
    };
  },
  deleteVoter: (postId: number | string, voterId: any) => {
    return {
      mutationFn: deleteVoter(postId, voterId),
    };
  },
  deleteManyVoters: (postId: number | string, voterIds: number[]) => {
    return {
      mutationFn: deleteManyVoters(postId, voterIds),
    };
  },
  createImport: (postId: number | string, scope: ImportScope) => {
    return {
      mutationFn: createImport(postId, scope),
    };
  },
  updateVotersDuplicate: (
    postId: number | string,
    voterDuplicateId: number,
    decision: 'keep' | null,
  ) => {
    return {
      mutationFn: updateVotersDuplicate(postId, voterDuplicateId, decision),
    };
  },
  verifyPin: () => {
    return {
      mutationFn: verifyPin(),
    };
  },
  translate: () => {
    return {
      mutationFn: translate,
    };
  },
  createPassword: () => {
    return {
      mutationFn: createPassword,
    };
  },
  resetPassword: (spaceId: number | string) => {
    return {
      mutationFn: resetPassword(spaceId),
    };
  },
  updatePassword: (userId: number | string, tokenId: string) => {
    return {
      mutationFn: updatePassword(userId, tokenId),
    };
  },
  updateMyPassword: () => {
    return {
      mutationFn: updateMyPassword,
    };
  },
  updateProfile: (spaceId: number | string) => {
    return {
      mutationFn: updateProfile(spaceId),
    };
  },

  //
  // Queries
  //
  getProfile: () => {
    return {
      queryKey: ['profile'],
      queryFn: getProfile,
    };
  },
  getMySpaces: () => {
    return {
      queryKey: ['my-spaces'],
      queryFn: getMySpaces,
    };
  },
  getCommentTranslation: (commentId: string | number, targetLang: string) => {
    return {
      queryKey: ['comment-translation', commentId?.toString(), targetLang],
      queryFn: getCommentTranslation(commentId, targetLang),
    };
  },
  getAddressCities: (postId: string | number) => {
    return {
      queryKey: ['address-cities', postId?.toString()],
      queryFn: getAddressCities(postId),
    };
  },
  getAttendances: (postId: string | number) => {
    return {
      queryKey: compact(['attendances', postId.toString()]),
      queryFn: getAttendances(postId),
    };
  },
  getComments: (postId: string, queryParams: object) => {
    return {
      queryKey: compact(['comments', postId, qs.stringify(queryParams)]),
      queryFn: getCommentsForPost(postId, queryParams),
    };
  },
  getDiscussionComments: (postId: string, queryParams: object) => {
    return {
      queryKey: compact(['comments', postId, qs.stringify(queryParams)]),
      queryFn: getDiscussionNotes(postId, queryParams),
    };
  },
  getPostStatus: (postId: number | string) => {
    return {
      queryKey: ['post-status', postId?.toString()],
      queryFn: getPostStatus(postId),
    };
  },
  getPost: (postId: string | number) => {
    return {
      queryKey: ['post', postId?.toString()],
      queryFn: getPost(postId),
    };
  },
  getFormResponse: (postId: string | number, formResponseId: number | '') => {
    return {
      queryKey: ['form-response', postId?.toString(), formResponseId?.toString()],
      queryFn: getFormResponse(postId, formResponseId),
    };
  },
  getFormResponses: (postId: string, queryParams: any = {}) => {
    return {
      queryKey: compact(['form-responses', postId, qs.stringify(queryParams)]),
      queryFn: getFormResponses(postId, queryParams),
    };
  },
  getReportReasons: () => {
    return {
      queryKey: ['report-reasons'],
      queryFn: getReportReasons(),
    };
  },
  getSecuredStrategySubmission: (postId: number | string, responseId: number | string) => {
    return {
      queryKey: ['secured-strategy-response', postId?.toString(), responseId?.toString()],
      queryFn: getSecuredStrategySubmission(postId, responseId),
    };
  },
  getQuestionResults: (questionId: string) => {
    return {
      queryKey: ['question-results', questionId],
      queryFn: getQuestionResults(questionId),
    };
  },
  getUiSections: (page: Page, queryParams: any = {}) => {
    return {
      queryKey: compact(['ui-sections', page, queryParams]),
      queryFn: getUiSections(page, queryParams),
    };
  },
  getVoters: (postId: string, queryParams: any = {}) => {
    return {
      queryKey: compact(['voters', postId, qs.stringify(queryParams)]),
      queryFn: getVoters(postId, queryParams),
    };
  },
  getImport: (postId: string | number, scope: ImportScope) => {
    return {
      queryKey: compact(['voters-import', postId?.toString(), scope]),
      queryFn: getImport(postId, scope),
    };
  },
  getVotersDuplicates: (postId: string | number, refresh: boolean = false) => {
    return {
      queryKey: compact(['voters-duplicates', refresh.toString(), postId?.toString()]),
      queryFn: getVotersDuplicates(postId, refresh),
    };
  },
} satisfies QueriesConfig<any>;

export default queriesConfig;
