import { Locale } from '@formatjs/intl-utils/src/types';

const getEthicsLinks = (locale: Locale) => {
  if (locale === 'fr') {
    return 'https://efalia.notion.site/Charte-thique-1969d13bc6d981908ab0f2e70306ae7b';
  }

  return 'https://efalia.notion.site/Ethical-charter-1939d13bc6d981d8ada5c21abb9efb08';
};

const getPrivacyLinks = (locale: Locale) => {
  if (locale === 'fr') {
    return 'https://efalia.notion.site/Politique-de-confidentialit-des-donn-es-1969d13bc6d981e8aecccacd09de3c99';
  }

  return 'https://efalia.notion.site/Privacy-Policy-1939d13bc6d981e68d61d8bbc70c50fa';
};

const getTermsLinks = (locale: Locale) => {
  if (locale === 'fr') {
    return 'https://efalia.notion.site/Conditions-g-n-rales-d-utilisation-1969d13bc6d9816193b8c55278475be0';
  }

  return 'https://efalia.notion.site/Terms-of-Use-1939d13bc6d981a79cb4d890b1537a51';
};

const getFaqsLinks = (locale: Locale) => {
  if (locale === 'fr') {
    return 'https://efalia.notion.site/Aide-1969d13bc6d9809797e0d775b70f4a0c';
  }

  return 'https://efalia.notion.site/Help-center-1939d13bc6d980948ea5eac5353479de';
};

const getAccessibilityLinks = (locale: Locale) => {
  if (locale === 'fr') {
    return 'https://efalia.notion.site/Accessibilit-1969d13bc6d981ceb75bc9a2140e21a9';
  }

  return 'https://efalia.notion.site/Accessibility-1939d13bc6d9818c90a6ef2d9d6f5866';
};

export { getEthicsLinks, getPrivacyLinks, getTermsLinks, getFaqsLinks, getAccessibilityLinks };
