import Cookies from 'universal-cookie';
import moment from 'moment';

import 'moment/dist/locale/af';
import 'moment/dist/locale/ar-ma';
import 'moment/dist/locale/en-gb';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/nl';

import { setApiLang } from '../Services/Api';

import messagesAf from './Locales/af.json';
import messagesAr from './Locales/ar.json';
import messagesEn from './Locales/en.json';
import messagesEs from './Locales/es.json';
import messagesFr from './Locales/fr.json';
import messagesNl from './Locales/nl.json';
import messagesXh from './Locales/xh.json';
import messagesZu from './Locales/zu.json';
import { LocaleType } from '../Utils/Types/Locales';

if (!Intl.PluralRules) {
  /* eslint-disable @typescript-eslint/no-require-imports */
  require('intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/af');
  require('@formatjs/intl-pluralrules/locale-data/ar');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/es');
  require('@formatjs/intl-pluralrules/locale-data/fr');
  require('@formatjs/intl-pluralrules/locale-data/nl');
  require('@formatjs/intl-pluralrules/locale-data/xh');
  require('@formatjs/intl-pluralrules/locale-data/zu');
  /* eslint-enable @typescript-eslint/no-require-imports */
}

// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
const flattenMessages = (nestedMessages: any, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages: Record<string, any>, key: string) => {
    const newMessages = { ...messages };
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      newMessages[prefixedKey] = value;
    } else {
      Object.assign(newMessages, flattenMessages(value, prefixedKey));
    }

    return newMessages;
  }, {});
};

const defaultLocale = 'en';
const supportedLocales: LocaleType[] = ['ar', 'en', 'es', 'fr', 'nl'];
const cookies = new Cookies();
const localeFromBackend = cookies.get('lang');
let currentLocale = localeFromBackend || defaultLocale;

const messages: Record<string, any> = {
  af: flattenMessages(messagesAf),
  ar: flattenMessages(messagesAr),
  en: flattenMessages(messagesEn),
  es: flattenMessages(messagesEs),
  fr: flattenMessages(messagesFr),
  nl: flattenMessages(messagesNl),
  xh: flattenMessages(messagesXh),
  zu: flattenMessages(messagesZu),
};

const monthShort: Record<string, string[]> = {
  af: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Des'.split('_'),
  ar: 'يناير_فبراير_مارس_أبريل_ماي_يونيو_يوليوز_غشت_شتنبر_أكتوبر_نونبر_دجنبر'.split('_'),
  en: 'Jan._Feb._Mar._Apr._May_Jun._Jul._Aug._Sep._Oct._Nov._Dec.'.split('_'),
  es: 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.'.split('_'),
  fr: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  nl: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Dec'.split('_'),
  xh: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Des'.split('_'),
  zu: 'Jan_Feb_Mrt_Apr_Mei_Jun_Jul_Aug_Sep_Okt_Nov_Des'.split('_'),
};

const updateMomentLocale = (locale: string) => {
  moment.updateLocale(locale, { monthsShort: monthShort[locale] });
};

const setCurrentLocale = (locale: any) => {
  if (!locale) {
    return;
  }

  if (locale === 'ar') {
    document.dir = 'rtl';
    updateMomentLocale('ar-ma');
  } else {
    document.dir = 'ltr';
    updateMomentLocale(locale);
  }

  setApiLang(locale);

  currentLocale = locale;
};

export { defaultLocale, supportedLocales, currentLocale, setCurrentLocale, messages, moment };
