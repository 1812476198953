import React from 'react';
import { Route, Routes, useLocation, matchPath, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useModal } from 'mui-modal-provider';
import { useCookies } from 'react-cookie';

import useSpace from '../../Hooks/useSpace';
import useCurrentUser from '../../Hooks/useCurrentUser';
import AuthenticatedRoute from '../../Components/AuthenticatedRoute';
import GuestRoute from '../../Components/GuestRoute';
import SpaceRoute from '../../Components/SpaceRoute';
import FullScreenLoader from '../../Components/FullScreenLoader';
import Analytics from '../../Services/AnalyticsService';
import { isAccessingViaCustomDomain } from '../../Utils/customDomain';
import CookieSettingsModal from '../../Components/CookieSettingsModal';
import { currentLocale } from '../../I18n';
import { spacePath } from '../../Utils/routes';
import EntitySubscriptionModal from '../../Components/EntitySubscriptionModal';
import { entitySubscriptionModalCookieName } from '../../Components/EntitySubscriptionModal/EntitySubscriptionModal';

const LazyHome = React.lazy(() => import('./Home'));
const LazyNpsForm = React.lazy(() => import('../../Components/NpsForm'));
const LazySearch = React.lazy(() => import('./Search'));
const LazyConsultations = React.lazy(() => import('./Consultations'));
const LazyAbout = React.lazy(() => import('./About'));
const LazyDashboard = React.lazy(() => import('../Dashboard'));
const LazyPostForm = React.lazy(() => import('../Posts/PostForm'));
const LazySecuredStrategies = React.lazy(
  () => import('../Posts/SecuredStrategies/MagicLinkWrapper'),
);
const LazyProfilePage = React.lazy(() => import('../Profile'));
const LazyNews = React.lazy(() => import('./News'));
const PasswordReset = React.lazy(() => import('./PasswordReset'));
const PasswordResetRequest = React.lazy(() => import('./PasswordResetRequest'));
const LazySignIn = React.lazy(() => import('./SignIn'));
const LazySignUp = React.lazy(() => import('./SignUp'));
const LazySignUpExtras = React.lazy(() => import('./SignupExtras'));
const LazyPostShow = React.lazy(() => import('../Posts/Show/PostShow'));
const LazyUnsubscribePage = React.lazy(() => import('./Unsubscribe'));
const NotFoundPage = React.lazy(() => import('../ErrorPage'));
const LazyParticipation = React.lazy(() => import('./Contributions/Participation'));
const LazyMessaging = React.lazy(() => import('./Contributions/Messaging'));
const LazyPrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const LazyTermsOfUse = React.lazy(() => import('./TermsOfUse'));

function SpaceRoutes() {
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const [cookies, setCookies] = useCookies();

  const { showModal } = useModal();
  const [searchParams] = useSearchParams();
  // NOTE: the useSpace query should only be enabled on a single place useQueryClient to prevent duplicate requests
  const { space, isLoading } = useSpace({ enabled: true });
  const { isLoggedIn } = useCurrentUser();

  const match = matchPath({ path: '/:spaceSlug/posts/:postSlug' }, location.pathname);
  const postId = match?.params?.postSlug?.split('-')[0];
  const redirectTo = searchParams.get('redirect_to');

  React.useEffect(() => {
    if (space?.id) {
      Analytics.updateSpaceSlug(space.slug);
      Analytics.client.trackView({
        custom_domain: isAccessingViaCustomDomain() ? 'custom' : 'self',
        space_slug: space.slug,
        user_role: space.current_user_role,
        post_id: postId,
      });
      trackPageView({
        customDimensions: [
          {
            id: 1,
            value: space.slug || '',
          },
          {
            id: 2,
            value: isAccessingViaCustomDomain() ? 'custom' : 'self',
          },
          {
            id: 3,
            value: space.current_user_role || '',
          },
        ],
      });
    }
  }, [location, space]);

  React.useEffect(() => {
    const dismissedModal = cookies[entitySubscriptionModalCookieName(space?.id)];

    if (space?.id && !space?.entity_subscription && isLoggedIn && !dismissedModal) {
      showModal(EntitySubscriptionModal, {
        onClose: () => {
          setCookies(entitySubscriptionModalCookieName(space?.id), true);
        },
      });
    }
  }, [space]);

  if (isLoading || !space.id || space.subscription_status === 'former_client') {
    return <FullScreenLoader />;
  }

  return (
    <>
      <CookieSettingsModal />
      {currentLocale === 'ar' && (
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic&display=swap"
            rel="stylesheet"
          />
        </Helmet>
      )}

      {space.show_nps_form && <LazyNpsForm />}
      <Routes>
        <Route path="/" element={<SpaceRoute component={LazyHome} />} />
        <Route path="about" element={<SpaceRoute component={LazyAbout} />} />
        <Route
          path="account/:sub_page"
          element={<AuthenticatedRoute component={LazyProfilePage} />}
        />
        <Route path="contributions/participation" element={<LazyParticipation />} />
        <Route path="contributions/messaging" element={<LazyMessaging />} />
        <Route path="consultations" element={<SpaceRoute component={LazyConsultations} />} />
        <Route path="news" element={<SpaceRoute component={LazyNews} />} />
        <Route path="dashboard/*" element={<AuthenticatedRoute component={LazyDashboard} />} />
        <Route path="posts/:postSlug" element={<SpaceRoute component={LazyPostShow} />} />
        <Route
          path="posts/:postSlug/edit"
          element={<AuthenticatedRoute component={LazyPostForm} />}
        />
        <Route path="posts/:postSlug/secured-strategies" element={<LazySecuredStrategies />} />
        <Route path="search" element={<SpaceRoute component={LazySearch} />} />
        <Route
          path="password-reset-request"
          element={<GuestRoute component={PasswordResetRequest} />}
        />
        <Route path="password-reset" element={<GuestRoute component={PasswordReset} />} />
        <Route path="login" element={<GuestRoute component={LazySignIn} />} />
        <Route
          path="signup"
          element={
            <GuestRoute
              component={LazySignUp}
              defaultRedirect={spacePath('signup_extras', {
                redirect_to: redirectTo,
              })}
            />
          }
        />
        <Route path="signup_extras" element={<AuthenticatedRoute component={LazySignUpExtras} />} />
        <Route path=":postType/new" element={<AuthenticatedRoute component={LazyPostForm} />} />
        <Route path="unsubscribe/:uuid/:list" element={<LazyUnsubscribePage />} />

        <Route
          path="pages/privacy-policy"
          element={<GuestRoute component={LazyPrivacyPolicy} redirect={false} />}
        />
        <Route
          path="pages/terms"
          element={<GuestRoute component={LazyTermsOfUse} redirect={false} />}
        />
        <Route element={<SpaceRoute component={NotFoundPage} redirect={false} />} />
      </Routes>
    </>
  );
}

export default SpaceRoutes;
