export type LocaleType = 'af' | 'ar' | 'en' | 'es' | 'fr' | 'nl' | 'xh' | 'zu';

interface LocaleDefinition {
  value: LocaleType;
  label: string;
}

export const LocalesDefinition: Array<LocaleDefinition> = [
  {
    value: 'af',
    label: 'Afrikaans',
  },
  {
    value: 'ar',
    label: 'العربية',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Español',
  },
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'nl',
    label: 'Nederlands',
  },
  {
    value: 'xh',
    label: 'isiXhosa',
  },
  {
    value: 'zu',
    label: 'isiZulu',
  },
];

export const locales = LocalesDefinition.reduce(
  (acc, locale) => {
    acc[locale.value] = locale.value;
    return acc;
  },
  {} as Record<LocaleType, LocaleType>,
);

export default LocalesDefinition;
