import { useState } from 'react';
import { useQuery } from 'react-query';
import { get } from 'lodash';

import ApiErrorsFormatter from '../Utils/ApiErrorsFormatter';
import { QueryConfig } from '../Services/queriesConfig';

interface useApiQueryProps<T> {
  query: QueryConfig<T>;
  options?: any;
  onSuccess?: (response: any) => void;
  onError?: (errors: any) => void;
  onSettled?: (response: any) => void;
}

function useApiQuery<T>({
  query,
  options,
  onSuccess = () => {},
  onError = () => {},
  onSettled = () => {},
}: useApiQueryProps<T>) {
  const [errors, setErrors] = useState();
  // in v4 uses queryKey and queryFn so we could spread query instead
  const { data, ...rest } = useQuery(query.queryKey, query.queryFn, {
    useErrorBoundary: true,
    enabled: true,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
    onSuccess: (response) => {
      if (response.ok) {
        onSuccess(get(response, 'data.data')); // prefer using return value instead of callback when possible
      } else {
        const apiErrors = ApiErrorsFormatter(get(response?.data, 'errors'));
        setErrors(apiErrors);
        onError(apiErrors);
      }
    },
    onSettled,
  });

  return {
    data: get(data, 'data.data'),
    paging: get(data, 'data.paging') || { total: 0, total_pages: 0 },
    count: get(data, 'data.paging.total') || 0,
    errors,
    ...rest,
  };
}

export default useApiQuery;
