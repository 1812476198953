import React from 'react';

import useApiQuery from '../Hooks/useApiQuery';
import queriesConfig from '../Services/queriesConfig';
import { getHydrationDataAsResponse } from '../Utils/hydration';
import { User } from '../Utils/Types/User';
import { logout as apiLogout } from '../Services/Api';
import useIntlProvider from '../Hooks/useIntlProvider';

interface CurrentUserContextType {
  currentUser: Partial<User>;
  isLoggedIn: boolean;
  isLoading: boolean;
  refetch: () => void;
  logout: () => Promise<void>;
}

export const CurrentUserContext = React.createContext<CurrentUserContextType>({
  currentUser: {},
  isLoggedIn: false,
  isLoading: false,
  refetch: () => {},
  logout: async () => {},
});

export function CurrentUserProvider({ children }: any) {
  const hydratedProfileResponse = getHydrationDataAsResponse('profile');
  const [currentUser, setCurrentUser] = React.useState<CurrentUserContextType['currentUser']>(
    hydratedProfileResponse?.data?.data,
  );
  const { setLocale } = useIntlProvider();
  const isLoggedIn = currentUser && Object.keys(currentUser).length > 0;

  const { refetch, isLoading } = useApiQuery({
    query: queriesConfig.getProfile(),
    onSuccess: (data) => {
      setCurrentUser(data);
    },
    options: {
      enabled: !hydratedProfileResponse,
    },
  });

  // Sign out from Google Auth SDK
  // just in case user signed in via Google
  const handleGoogleSignOut = () => {
    if (window.gapi && window.gapi.auth2) {
      window.gapi.auth2.getAuthInstance().signOut();
    }
  };

  const logout = React.useCallback(async (): Promise<void> => {
    await apiLogout();
    handleGoogleSignOut();
    setCurrentUser({});
    refetch();
  }, []);

  React.useEffect(() => {
    if (isLoggedIn && currentUser.lang) {
      setLocale(currentUser.lang);
    }
  }, [currentUser, isLoggedIn]);

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser,
        isLoggedIn,
        isLoading,
        refetch,
        logout,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserContext;
