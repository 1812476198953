import React from 'react';
import { Navigate } from 'react-router-dom';

import FullScreenLoader from './FullScreenLoader';
import { loginPath } from '../Utils/routes';
import useSpace from '../Hooks/useSpace';
import useCurrentUser from '../Hooks/useCurrentUser';

function AuthenticatedRoute({ component: Component, props }: any) {
  const { space } = useSpace();
  const { isLoggedIn, isLoading } = useCurrentUser();

  if (isLoading || !space?.slug) return <FullScreenLoader />;

  if (!isLoggedIn) {
    return <Navigate replace to={loginPath()} />;
  }

  return <Component space={space} {...props} />;
}

export default AuthenticatedRoute;
