import React from 'react';
import Button from '@mui/material/Button';

function ButtonWithLoader({ children, isFetching, ...props }: any) {
  return (
    <Button loading={isFetching} disabled={isFetching} {...props}>
      {children}
    </Button>
  );
}

export default ButtonWithLoader;
