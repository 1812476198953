import React, { createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorPage from '../../Pages/ErrorPage';
import appsignal from '../../Services/ErrorReportingService';

// Create a context to expose error state
export const ErrorBoundaryContext = createContext({ hasError: false });

// Hook to consume the error state
export const useErrorBoundary = () => useContext(ErrorBoundaryContext);

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

class ErrorBoundaryClass extends React.Component<
  ErrorBoundaryProps & {
    onError: () => void;
    locationKey: string;
  },
  {
    hasError: boolean;
    prevLocationKey: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      prevLocationKey: props.locationKey,
    };
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (props.locationKey !== state.prevLocationKey) {
      return {
        hasError: false,
        prevLocationKey: props.locationKey,
      };
    }
    return null;
  }

  static getDerivedStateFromError(error: any) {
    try {
      appsignal.sendError(error);
    } catch (e) {
      console.log(e);
    }

    return { hasError: true };
  }

  componentDidUpdate(_prevProps: any, prevState: any) {
    if (!prevState.hasError && this.state.hasError) {
      this.props.onError();
    }
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    // Provide the error state through context
    return (
      <ErrorBoundaryContext.Provider value={{ hasError }}>
        {hasError ? <ErrorPage status={500} /> : children}
      </ErrorBoundaryContext.Provider>
    );
  }
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleError = () => {
    navigate('/500');
  };

  return (
    <ErrorBoundaryClass onError={handleError} locationKey={location.pathname}>
      {children}
    </ErrorBoundaryClass>
  );
}

export default ErrorBoundary;
