import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MatomoProvider } from '@datapunt/matomo-tracker-react';
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import AnalyticsService from '../Services/AnalyticsService';
import { IntlProvider } from '../Contexts/IntlProviderContext';
import appsignal from '../Services/ErrorReportingService';
import { CurrentUserProvider } from '../Contexts/CurrentUserContext';

function RootContainer({ children }: any) {
  const queryClient = new QueryClient();

  return (
    <AppSignalErrorBoundary instance={appsignal}>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider>
            <MatomoProvider value={AnalyticsService.matomoInstance}>
              <BrowserRouter>{children}</BrowserRouter>
            </MatomoProvider>
          </CurrentUserProvider>
        </QueryClientProvider>
      </IntlProvider>
    </AppSignalErrorBoundary>
  );
}

export default RootContainer;
