import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { get } from 'lodash';
import { extractErrorsForNestedAttributes } from '../Services/ApiErrorHelpers';
import { MutationConfig } from '../Services/queriesConfig';

export interface useApiMutationProps {
  query: MutationConfig;
  onSuccess?: (response: any) => void;
  onError?: (errors: any, data: any) => void;
  onSettled?: (response: any) => void;
}

function useApiMutation({
  query,
  onSuccess = () => {},
  onError = () => {},
  onSettled = () => {},
}: useApiMutationProps) {
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState({});
  const mutation = useMutation(query.mutationFn, {
    useErrorBoundary: true,
    onSuccess: (response: any) => {
      if (response.ok) {
        onSuccess(get(response, 'data.data')); // prefer using return value instead of callback when possible
      } else {
        const apiErrors = extractErrorsForNestedAttributes(response);

        setErrors(apiErrors);
        onError(apiErrors, response.data);
      }
    },
    onSettled,
  });

  return {
    ...mutation,
    data: get(mutation, 'data.data'),
    queryClient,
    errors,
  };
}

export default useApiMutation;
