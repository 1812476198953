import React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';

import NavBar from '../Components/NavBar/Default';
import Footer, { FOOTER_HEIGHT, MOBILE_FOOTER_HEIGHT } from '../Components/Footer/Footer';
import MetaTags from '../Components/MetaTags/MetaTags';
import useSpace from '../Hooks/useSpace';
import useCurrentUser from '../Hooks/useCurrentUser';

const StyledMain = styled('main')(({ theme: { breakpoints, mixins } }: any) => ({
  minHeight: `calc(100vh - ${mixins.toolbar['@media (min-width:600px)'].minHeight}px - ${FOOTER_HEIGHT}px)`,
  [breakpoints.down('md')]: {
    minHeight: `calc(100vh - ${mixins.toolbar.minHeight}px - ${MOBILE_FOOTER_HEIGHT}px)`,
  },
}));

const ToolbarPusher = styled('div')(({ theme }: any) => ({
  ...theme.mixins.toolbar,
}));

interface LayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

function Layout({ children }: LayoutProps) {
  const intl = useIntl();
  const { space, isLoading: isLoadingSpace } = useSpace();
  const { currentUser, isLoggedIn } = useCurrentUser();
  const { picture, full_name: fullName, initials, verified } = currentUser || {};
  const role = space ? space.current_user_role : 'citizen';

  return (
    <>
      <MetaTags
        description={intl.formatMessage({ id: 'META_TAGS.DEFAULT_DESCRIPTION' })}
        noindex={!space.public}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <NavBar
          isLoggedIn={isLoggedIn}
          pictureS3Key={picture?.s3_key}
          filename={picture?.title}
          space={space}
          fullName={fullName}
          verified={verified}
          initials={initials}
          role={role}
          isLoading={isLoadingSpace}
        />

        <ToolbarPusher />
        <StyledMain>{children}</StyledMain>

        <Footer />
      </Box>
    </>
  );
}

export default Layout;
