// Make sure react-hot-loader is required before react and react-dom:
import { hot } from 'react-hot-loader/root';
import React, { Suspense } from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { ErrorBoundary as AppSignalErrorBoundary } from '@appsignal/react';
import ModalProvider from 'mui-modal-provider';
import { NotificationsProvider } from '@toolpad/core/useNotifications';

import Layout from './Layout/Layout';
import EngageThemeProvider from './Layout/EngageThemeProvider';
import DelayedLoader from './Components/DelayedLoader';
import SpaceRoutes from './Pages/Entities/SpaceRoutes';
import { isAccessingViaCustomDomain } from './Utils';
import ErrorBoundary from './Components/ErrorBoundary';
import AnalyticsService from './Services/AnalyticsService';
import appsignal from './Services/ErrorReportingService';
import { CookieSettingsProvider } from './Contexts/CookieSettingsContext';
import ScrollToTop from './Utils/ScrollToTop';
import { CurrentUserProvider } from './Contexts/CurrentUserContext';
import { IntlProvider } from './Contexts/IntlProviderContext';

const ErrorPage = React.lazy(() => import('./Pages/ErrorPage'));
const HomeEngage = React.lazy(() => import('./Pages/Home'));

function AppRoutes() {
  const accessFromFluicityDomain = !isAccessingViaCustomDomain();
  const { enableLinkTracking } = useMatomo();

  enableLinkTracking();

  return (
    <Routes>
      <Route path="/" element={accessFromFluicityDomain ? <HomeEngage /> : <SpaceRoutes />} />
      <Route path="/404" element={<ErrorPage status={404} />} />
      <Route path="/500" element={<ErrorPage status={500} />} />
      <Route path="/home" element={<HomeEngage />} />
      <Route path="/:spaceSlug/*" element={<SpaceRoutes />} />
      <Route element={<ErrorPage />} />
    </Routes>
  );
}

const App: React.FC = () => {
  const queryClient = new QueryClient();

  return (
    <AppSignalErrorBoundary instance={appsignal}>
      <IntlProvider>
        <QueryClientProvider client={queryClient}>
          <CurrentUserProvider>
            <MatomoProvider value={AnalyticsService.matomoInstance}>
              <BrowserRouter>
                <CookieSettingsProvider>
                  <EngageThemeProvider>
                    <NotificationsProvider
                      slotProps={{
                        snackbar: { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
                      }}
                    >
                      <ModalProvider>
                        <Layout>
                          <ErrorBoundary>
                            <Suspense fallback={<DelayedLoader />}>
                              <ScrollToTop />
                              <AppRoutes />
                            </Suspense>
                          </ErrorBoundary>
                        </Layout>
                      </ModalProvider>
                    </NotificationsProvider>
                  </EngageThemeProvider>
                </CookieSettingsProvider>
              </BrowserRouter>
            </MatomoProvider>
          </CurrentUserProvider>
        </QueryClientProvider>
      </IntlProvider>
    </AppSignalErrorBoundary>
  );
};

export default hot(App);
